import { get } from 'lodash';
import { createSelector } from 'reselect';
import { PIN_REQUIRED_ROLES } from 'utils/roles';

export const getUserStudies = state => get(state, 'dashboard.userStudies', []);
export const getCurrentUser = state => get(state, 'dashboard.currentUser', {});

export const getCurrentUserDisplayName = createSelector(getCurrentUser, ({ name }) => {
  if (!name) return null;

  return `${name.first} ${name.last}`;
});

export const getCurrentUserShouldSetPin = createSelector(getCurrentUser, ({ role, ...currentUser }) => {
  const unblindingPin = get(currentUser, 'unblinding_pin');
  
  return !unblindingPin && PIN_REQUIRED_ROLES.includes(role);
});
