import { axiosRef } from 'config/axios';

export const fetchCurrentUser = ({ authToken }) => {
  return new Promise((resolve, reject) => {
    axiosRef.get('user/users/current/me', {
      headers: {
        'Authorization': `Bearer ${authToken}`,
      },
    }).then(({ data }) => {
      resolve(data?.data);
    }).catch(error => {
      reject(error);
    });
  });
};
