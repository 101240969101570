import { useState, useEffect, useMemo } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { loginRequest } from 'config/msal';

export function useApi(callback) {
  const { instance, accounts } = useMsal();

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const account = useMemo(() => accounts?.[0], [accounts]);

  useEffect(() => {
    instance.acquireTokenSilent({
      ...loginRequest,
      account,
    }).then(resp => {
      if (!!resp?.idToken) {
        callback({
          authToken: resp.idToken,
        }).then(resp => {
          setData(resp);
        }).catch(error => {
          console.error(error);
        }).finally(() => {
          setIsLoading(false);
        });
      }
    }).catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect({
          ...loginRequest,
          prompt: 'login',
        });
      }
    }).finally(() => {
      setIsLoading(false);
    });
  }, [callback, instance, account]);

  return { data, isLoading };
};
