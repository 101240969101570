import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AuthenticationProvider } from 'components';
import { LayoutDefault } from 'layouts';
import { Dashboard } from 'pages';

const router = createBrowserRouter([{
  path: '/',
  element: (
    <LayoutDefault>
      <Dashboard />
    </LayoutDefault>
  ),
}]);

const App = () => {
  return (
    <div id="app-container">
      <AuthenticationProvider>
        <RouterProvider router={router} />
      </AuthenticationProvider>
    </div>
  );
};

export default App;
