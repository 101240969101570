import { createSlice } from '@reduxjs/toolkit';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    userStudies: [],
    currentUser: {},
  },
  reducers: {
    setUserStudies: (state, action) => {
      state.userStudies = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
  },
});

export const {
  setUserStudies,
  setCurrentUser,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
