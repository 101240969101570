import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { setActivityTimestamp, getActivityTimestamp } from 'utils/auth';
import { InactivityManager } from 'components';

const AuthenticationProvider = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();

  const inactivityInterval = useRef();

  const [inactivitySeconds, setInactivitySeconds] = useState(0);

  const account = useMemo(() => accounts?.[0], [accounts]);

  // Redirect empty sessions to the login page
  useEffect(() => {
    if (inProgress !== InteractionStatus.None) return;

    if (!isAuthenticated && !account) {
      instance.loginRedirect({ prompt: 'login' });
    }
  }, [inProgress, isAuthenticated, account, instance]);

  useEffect(() => {
    inactivityInterval.current = setInterval(() => {
      const inactivityTime = Date.now() - getActivityTimestamp();

      setInactivitySeconds(Math.floor(inactivityTime / 1000));
    }, 1000);

    return () => {
      clearInterval(inactivityInterval.current);

      inactivityInterval.current = null;
    };
  }, []);

  const recordUserActivity = useCallback(() => {
    setActivityTimestamp(Date.now());
  }, []);

  const resetUserAuth = useCallback(() => {
    instance.logoutRedirect();
  }, [instance]);

  useEffect(() => {
    recordUserActivity();
  }, [recordUserActivity]);

  return (
    <InactivityManager
      inactivitySeconds={inactivitySeconds}
      recordUserActivity={recordUserActivity}
      destroyUserSession={resetUserAuth}>
      {children}
    </InactivityManager>);
};

export default AuthenticationProvider;
