import React from 'react';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import './styles.scss';

const LoadingIndicator = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="loading-indicator">
      <RotateRightIcon />
    </div>
  );
};

export default LoadingIndicator;
