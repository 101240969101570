import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApi } from 'hooks/api';
import { LoadingIndicator } from 'components';
import { UserStudyItem } from './components';
import { fetchAllStudies } from 'api/studies';
import { fetchCurrentUser } from 'api/users';
import { setUserStudies, setCurrentUser } from './slice';
import { getUserStudies, getCurrentUserDisplayName, getCurrentUserShouldSetPin } from './selectors';
import './styles.scss';

const Dashboard = () => {
  const dispatch = useDispatch();

  const {
    data: allStudies,
    isLoading: isFetchAllStudiesLoading,
  } = useApi(fetchAllStudies);

  const {
    data: currentUser,
    isLoading: isFetchCurrentUserLoading,
  } = useApi(fetchCurrentUser);

  const userStudies = useSelector(getUserStudies);
  const currentUserDisplayName = useSelector(getCurrentUserDisplayName);
  const currentUserShouldSetPin = useSelector(getCurrentUserShouldSetPin);

  const isLoading = useMemo(() => {
    return isFetchAllStudiesLoading || isFetchCurrentUserLoading;
  }, [isFetchAllStudiesLoading, isFetchCurrentUserLoading]);

  useEffect(() => {
    if (!isFetchAllStudiesLoading && Array.isArray(allStudies)) {
      dispatch(setUserStudies(allStudies));
    }
  }, [allStudies, isFetchAllStudiesLoading, dispatch]);

  useEffect(() => {
    if (!isFetchCurrentUserLoading && !!currentUser) {
      dispatch(setCurrentUser(currentUser));
    }
  }, [currentUser, isFetchCurrentUserLoading, dispatch]);

  useEffect(() => {
    if (currentUserShouldSetPin) {
      // TODO Move this and other non-authenticated pages into Portico
      window.location.href = '/app/create-pin';
    }
  }, [currentUserShouldSetPin]);

  return (
    <div id="dashboard-page">
      <LoadingIndicator isLoading={isLoading} />

      {!isLoading ? (
        <header>
          <h1>{!!currentUserDisplayName ? `Welcome to Korio, ${currentUserDisplayName}` : null}</h1>
          <p>Please select a study from the list below</p>
        </header>
      ) : null}

      <ul className="user-study-list">
        {userStudies.map(userStudy => (
          <UserStudyItem
            key={`user-study-${userStudy.studyId}`}
            {...userStudy}
          />
        ))}
      </ul>
    </div>
  );
};

export default Dashboard;
